<template>
  <div class="home">
    <van-nav-bar title="我的前端和后端技术学习记录" fixed/>
    <br>
    <br>
    <p>
      我是首页，放点什么好呢？
    </p>
    <div class="floor">
      <a href="http://beian.miit.gov.cn">豫ICP备2021003369号</a>
    </div>
  </div>
</template>

<script>

  export default {
    name: '我的前端和后端技术学习记录',
    components: {
    }
  }
</script>

<style>
	.floor{
    width: 100%;
    position: absolute;
    bottom: 100px;
    text-align: center;
  }
</style>